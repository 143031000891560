import { DateTime } from 'luxon';
import { JobMode, JobSearchDoc } from '@webacq/wa-shared-definitions';
import { config } from './config';
import { AuthContextType } from './context/auth';
import { ServiceError } from './services/api';
import { PermissionInfo } from './services/wamService';
import { GlobalPermissions, QueryStrings, SOLRFieldMapping } from './shared/constants';
import {
    PaginatedTableFilterSpec,
    PaginatedTableSortingSpec,
    PaginatedTableDateFilterValue,
    PaginatedTableFilterDateOperator,
} from './components/PaginatedTable/types';

export const pluralSuffix = (count?: number, suffix = 's', alternate = ''): string =>
    count !== 1 ? suffix : alternate;

const getEnvTail = (): string => {
    switch (config.env) {
        case 'PROD':
            return '/PROD';
        case 'BETA':
            return '/BETA';
        case 'DEV':
        case 'LOCAL':
            return '/DEV';
    }

    return '';
};

export const launchMnemonic = (mnemonic: string, tail?: string): void => {
    if (mnemonic) {
        window.open(`bbg://screens/${mnemonic}${tail ? ' ' + tail : ''}`);
    }
};

export const launchCRWL = (jobConfigId?: string): void => {
    if (jobConfigId) {
        launchMnemonic('CRWL', `${getEnvTail()} /JC ${jobConfigId}`);
    }
};

export const launchNSN = (suid?: string): void => {
    if (suid) {
        launchMnemonic('NSN', suid);
    }
};

export const createQuickLink = (tail: string): string => {
    return `{CRWL HUB ${getEnvTail()} ${tail}}`;
};

export const launchUrl = (url?: string): void => {
    if (url) {
        // prepend with scheme if not already present
        if (!/^\s*https*:\/\//i.test(url)) {
            url = `https://${url}`;
        }
        window.open(url);
    }
};

export const getSearchRoute = (searchterm: string, skipFacets?: string[]): string => {
    let url = `/?${QueryStrings.SEARCH}=${searchterm || ''}`;
    if (skipFacets) {
        skipFacets.forEach((facet) => (url += `&skipfacets=${facet}`));
    }
    return url;
};

export const getJobConfigRoute = (jobConfigId?: string, jobMode?: JobMode): string => {
    let url = `/job-config/${jobConfigId || ''}`;
    if (jobMode) {
        url += `?${QueryStrings.JOB_MODE}=${jobMode}`;
    }
    return url;
};

export function getJobRunRoute(id: string): string {
    return `/run/${encodeURIComponent(id)}`;
}

export function getHTMLDiffRoute(jobConfigId: string, deliveryUrl: string, acquisitionTime?: Date): string {
    let url = `/htmldiff/${jobConfigId}/${encodeURIComponent(deliveryUrl)}`;
    if (acquisitionTime) {
        url += `?${QueryStrings.ACQUISITION_TIME}=${acquisitionTime.getTime()}`;
    }
    return url;
}

export const getAlertConfigRoute = (jobConfigId?: string, alertConfigId?: string): string => {
    let url = `/alert-config/${jobConfigId || ''}`;
    if (alertConfigId) {
        url += `?${QueryStrings.ALERT_CONFIG_ID}=${alertConfigId}`;
    }
    return url;
};

export const getHumioLink = (logConfigName: string, query: string, start?: Date, end?: Date): string => {
    let stage = '';
    switch (config.env) {
        case 'PROD':
            stage = 'prod';
            break;
        case 'BETA':
            stage = 'beta';
            break;
        case 'DEV':
        case 'LOCAL':
            stage = 'dev';
            break;
    }

    const url = new URL('https://humio.prod.bloomberg.com/wam/search');
    url.searchParams.append(
        'query',
        `* | #logConfigName=${logConfigName} #parentCluster=bpaas | bpaasStage=${stage}-* ${query}`
    );
    url.searchParams.append('fullscreen', 'false');
    url.searchParams.append('live', 'false');
    if (start) {
        url.searchParams.append('start', start.getTime() + '');
    }
    if (end) {
        url.searchParams.append('end', end.getTime() + '');
    }

    return url.toString();
};

export const jobSchedule2text = (job: JobSearchDoc): string => {
    if (!job.schedule) {
        return 'never';
    }

    try {
        const parsedSchedule = JSON.parse(job.schedule);
        const rrule = parsedSchedule.timepoint.icalendar_rrule;

        const frequencyMapping: Record<string, string> = {
            YEARLY: 'year',
            MONTHLY: 'month',
            WEEKLY: 'week',
            DAILY: 'day',
            HOURLY: 'hour',
            MINUTELY: 'minute',
            SECONDLY: 'second',
        };

        const frequency = frequencyMapping[rrule.freq];

        let readable =
            rrule.interval && rrule.interval !== 1 ? `every ${rrule.interval} ${frequency}s` : `every ${frequency}`;

        if (rrule.dtstart) {
            readable += ` starting at ${formatTimestamp(new Date(rrule.dtstart))}`;
        }
        if (rrule.until) {
            readable += ` until ${formatTimestamp(new Date(rrule.until))}`;
        }

        return readable;
    } catch (e) {
        console.error(e);
        console.error(job);
        return '[invalid schedule]';
    }
};

export async function wrapApiCall<T>(
    authContext: AuthContextType,
    apiFunc: () => T,
    errorFunc?: (e: Error) => void,
    cleanupFunc?: () => void
): Promise<T | undefined> {
    return wrapApiCall2(authContext, undefined, apiFunc, errorFunc, cleanupFunc);
}

export async function wrapApiCall2<T, RT>(
    authContext: AuthContextType,
    defaultReturnVal: RT,
    apiFunc: () => T,
    errorFunc?: (e: Error) => void,
    cleanupFunc?: () => void
): Promise<T | RT> {
    try {
        return await apiFunc();
    } catch (e) {
        if (e instanceof ServiceError) {
            console.error(e);
            if (e.code === 401) {
                authContext.setAuthData({ isAuthenticated: false });
            }
        }

        errorFunc && errorFunc(e as Error);
    } finally {
        cleanupFunc && cleanupFunc();
    }

    return defaultReturnVal;
}

interface Permission {
    hasAccess: boolean;
    statusMessage?: string;
}

export const getPermissionInfo = (permissions: PermissionInfo[], level: GlobalPermissions): Permission => {
    const permission = permissions.find((permission) => permission.action === level);
    if (permission) {
        return {
            hasAccess: permission.permission === 'allowed',
            statusMessage: permission.message,
        };
    }

    // we should never end up here, but let's cover our ...
    return {
        hasAccess: false,
        statusMessage: 'Unauthorized to access...',
    };
};

export const formatDuration = (durationInMilliseconds: number): string => {
    if (durationInMilliseconds < 1000) {
        return `${durationInMilliseconds} ms`;
    }

    let seconds = Math.floor(durationInMilliseconds / 1000);

    if (seconds < 60) {
        // if less than a minute, and we have milliseconds
        // then display with 1 decimal (s.ms)
        const milliseconds = durationInMilliseconds - seconds * 1000;
        if (milliseconds !== 0) {
            return `${(durationInMilliseconds / 1000).toFixed(1)} seconds`;
        }
    }

    // omit milliseconds if runtime is > 1 min...

    let minutes = 0;
    let hours = 0;

    minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    if (minutes >= 60) {
        hours = Math.floor(minutes / 60);
        minutes -= hours * 60;
    }

    const hoursTxt = hours > 0 ? `${hours} hour${pluralSuffix(hours)}` : '';
    const minutesTxt = minutes > 0 ? `${minutes} minute${pluralSuffix(minutes)}` : '';
    const secondsTxt = seconds > 0 ? `${seconds} second${pluralSuffix(seconds)}` : '';

    return `${hoursTxt} ${minutesTxt} ${secondsTxt}`.trim();
};

export const formatTimestamp = (timestamp?: Date): string => {
    if (timestamp) {
        const dt = DateTime.fromJSDate(timestamp);

        if (dt.isValid) {
            return `${dt.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)} ${dt.toFormat('ZZZZ')}`;
        }
    }

    return '';
};

export const formatLargeNumber = (num: number): string => {
    const origNum = num;
    const base = 1000;

    if (num >= 0) {
        for (const unit of ['', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']) {
            if (num < base) {
                return `${Math.floor(num)}${unit}`;
            }

            num /= base;
        }
    }

    return `${origNum}`;
};

export const addEllipsis = (str: string, maxLen: number): string => {
    if (str.length <= maxLen) {
        return str;
    }

    const compLen = Math.floor((maxLen - 3) / 2);
    return `${str.substring(0, compLen)}…${str.substring(str.length - compLen)}`;
};

export const createSolrFilter = (filterSpec?: PaginatedTableFilterSpec): string => {
    return (filterSpec || [])
        .reduce((prev, curr) => {
            const solrField = SOLRFieldMapping[curr.field];

            if (solrField && curr.value !== undefined) {
                switch (curr.type) {
                    case 'numeric':
                        prev.push(`${solrField}:${curr.value}`);
                        break;

                    case 'boolean':
                        prev.push(`${solrField}:${curr.value ? 'true' : 'false'}`);
                        break;

                    case 'date':
                        {
                            const value = curr.value as PaginatedTableDateFilterValue;
                            if (value.date) {
                                const startOfDay = new Date(
                                    value.date.getFullYear(),
                                    value.date.getMonth(),
                                    value.date.getDate(),
                                    0,
                                    0,
                                    0,
                                    0
                                );

                                const endOfDay = new Date(
                                    value.date.getFullYear(),
                                    value.date.getMonth(),
                                    value.date.getDate(),
                                    23,
                                    59,
                                    59,
                                    999
                                );

                                switch (value.operator) {
                                    case PaginatedTableFilterDateOperator.Before:
                                        prev.push(`${solrField}:[* TO ${startOfDay.toISOString()}]`);
                                        break;

                                    case PaginatedTableFilterDateOperator.On:
                                        prev.push(
                                            `${solrField}:[${startOfDay.toISOString()} TO ${endOfDay.toISOString()}]`
                                        );
                                        break;

                                    case PaginatedTableFilterDateOperator.After:
                                        prev.push(`${solrField}:[${endOfDay.toISOString()} TO *]`);
                                        break;
                                }
                            }
                        }
                        break;

                    case 'string':
                        prev.push(`${solrField}:*${curr.value}*`);
                        break;

                    // case 'texts': {
                    //     const texts = filter.value as PageableTableFilterTextsValue;
                    //     const condition = texts.map((text) => `${solrField}:${text}`).join(' OR ');

                    //     if (condition) {
                    //         return `${prev} AND (${condition})`;
                    //     } else {
                    //         return prev;
                    //     }
                    // }
                }
            }

            return prev;
        }, [] as string[])
        .join(' AND ');
};

export const createSolrSorting = (sortSpec?: PaginatedTableSortingSpec): string => {
    return (sortSpec || [])
        .reduce((prev, curr) => {
            if (curr.field && curr.direction) {
                const solrField = SOLRFieldMapping[curr.field];
                if (solrField) {
                    prev.push(`${solrField} ${curr.direction}, id ${curr.direction}`);
                }
            }

            return prev;
        }, [] as string[])
        .join(',');
};
