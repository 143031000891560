import React from 'react';
import { Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';

interface ContentProps {
    children?: React.ReactNode;
    subtitle?: string | React.ReactElement;
    quickLinkTail?: string;
}

const Content = (props: ContentProps): JSX.Element => {
    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Header subtitle={props.subtitle} quickLinkTail={props.quickLinkTail} />
            <Box flexGrow={1} overflow="hidden">
                {props.children}
            </Box>
            <Footer year={2023} />
        </Box>
    );
};

export default Content;
