import React, { useState, useEffect } from 'react';
import { Theme, Alert, Toolbar, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Content from '../components/Content';
import { useQueryParam } from 'use-query-params';
import wamService  from '../services/wamService';
import { config } from '../config';
import { Download, OpenInNew } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
    urlToolbar: {
        background: theme.palette.grey[700]
    },
    url: {
        maxWidth: '80vw',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    previewContainer: {
        background: '#fff',
        border: 'none',
        color: '#000',
        width: '100%',
        height: '100%',
    },
    textContent: {
        margin: 0,
        padding: theme.spacing(2),
        fontSize: 12,
        height: '100%',
        overflow: 'auto',
    },
}));
const ObjectViewer = (): JSX.Element => {
    const classes = useStyles();
    const [errorMsg, setErrorMsg] = useState('');
    const [stringContent, setContent] = useState<string | undefined>();
    const [b64Content, setB64Content] = useState<string | undefined>();
    const [showRaw, setShowRaw] = useState(false);
    const [objectUrl] = useQueryParam<string>('url');

    useEffect(() => {
        (async() => {
            if (objectUrl) {
                try {
                    const resp = await wamService.fetchBcosContent(objectUrl, true);
                    console.log('bcos response:', resp);
                    if (!resp) {
                        throw new Error('Could not retrieve object.');
                    }
                    const { headers } = resp;
                    const bytes = resp.content as string;
                    const contentType = headers['content-type'];

                    setErrorMsg('');
                    setShowRaw(false);
                    setContent(undefined);
                    setB64Content(undefined);

                    if (!bytes) {
                        setErrorMsg('Content is empty');
                        setContent('');
                        setShowRaw(true);
                        return;
                    }
                    if (/application\/pdf/.test(contentType)) {
                        setB64Content(`data:${contentType};base64,${bytes}`);
                        return;
                    }
                    const content = Buffer.from(bytes, 'base64').toString();
                    if (/text\/html/.test(contentType)) {
                        // TODO: for html docs, when we're able to get the original url for the content, add <base /> tag in head
                        setContent(content);
                    } else if (/(application\/json)/.test(contentType)) {
                        setShowRaw(true);
                        setContent(JSON.stringify(JSON.parse(content), null, 2));
                    } else {
                        setShowRaw(true);
                        setContent(content);
                    }

                } catch(e) {
                    console.error(e);
                    setErrorMsg((e as Error).message);
                }
            } else {
                setErrorMsg('Missing document URL');
            }
        })()
    }, [objectUrl]);

    const handleOpenDocument = (download = false) => {
        const downloadUrl = `${config.apiUrl}/wam/ui/object?url=${window.encodeURIComponent(objectUrl)}&download=${download}&type=bcos`;
        window.open(downloadUrl, '_blank');
    };

    const iframeProps = Object.fromEntries([
        ['style', { border: 'none' }],
        ['width', '100%'],
        ['height', '100%'],
        ['src', b64Content],
        ['srcDoc', stringContent],
    ].filter(([, val]) => !!val));

    return (
        <Content subtitle="Document Viewer">
            {objectUrl && (
                <Toolbar className={classes.urlToolbar} variant="dense">
                    <div className={classes.url}>{objectUrl}</div>
                    &nbsp;&nbsp;
                    <Tooltip title="Open document in new tab">
                        <IconButton color="primary" onClick={() => handleOpenDocument(false)}>
                            <OpenInNew />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download document">
                        <IconButton color="primary" onClick={() => handleOpenDocument(true)}>
                            <Download />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            )}

            {errorMsg && (
                <Alert severity="error">
                    {errorMsg}
                </Alert>
            )}
            <div className={classes.previewContainer}>
                {(stringContent || b64Content) && !showRaw && (
                    <iframe {...iframeProps} />
                )}
                {stringContent && showRaw && (
                    <pre className={classes.textContent}>
                        {stringContent}
                    </pre>
                )}
            </div>

        </Content>
    );
};

export default ObjectViewer;
