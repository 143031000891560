import React from 'react';
import { Paper, Box, Tooltip } from '@mui/material';
import { JobDeliverySearchDoc } from '@webacq/wa-shared-definitions';
import { FullTreeIcon, BranchIcon } from '@webacq/wam-ui-components';
import { HighlightRecord } from '@webacq/wa-shared-servicewrappers';
import { DocTypes } from '../../shared/constants';
import { formatTimestamp, launchUrl, pluralSuffix } from '../../utils';
import { useStyles } from './commonStyles';
import CardTypeHeader from './CardTypeHeader';
import InfoField from './InfoField';
import ExtraHighlightedElements from './ExtraHighlightedElements';
import CardTitleHeader from './CardTitleHeader';
import ActionPopupMenu from './ActionPopupMenu';

const explicitKeys = ['jobConfigName', 'jobConfigId', 'jobMode', 'bbdsTranId', 'runId', 'provRootStepid'];

export type JobDeliverySearchDocCardProps = {
    jobDelivery: JobDeliverySearchDoc;
    highlightData?: HighlightRecord;
    onOpen: () => void;
};

const JobDeliverySearchDocCard = (props: JobDeliverySearchDocCardProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Paper elevation={1} className={`${classes.paddedBox} delivery-search-card`}>
            <CardTypeHeader type={DocTypes.JOB_DLV} showIcon />

            <CardTitleHeader
                highlightData={props.highlightData}
                jobConfigName={props.jobDelivery.jobConfigName}
                jobConfigVersion={props.jobDelivery.jobConfigVersion}
                jobConfigId={props.jobDelivery.jobConfigId}
                renderActions={() => (
                    <ActionPopupMenu
                        jobConfigId={props.jobDelivery.jobConfigId}
                        jobMode={props.jobDelivery.jobMode}
                        hideAlertItem
                    />
                )}
                extraElements={
                    props.jobDelivery.isComplete ? (
                        <Tooltip title="Complete Run">
                            <span>
                                <FullTreeIcon />
                            </span>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Branch Delivery">
                            <span>
                                <BranchIcon />
                            </span>
                        </Tooltip>
                    )
                }
                onClick={props.onOpen}
            />

            <Box className={classes.infoRow}>
                {props.jobDelivery.jobMode && (
                    <InfoField
                        content={{ label: 'Mode:', value: props.jobDelivery.jobMode, searchKey: 'jobMode' }}
                        highlightData={props.highlightData}
                    />
                )}
                {props.jobDelivery.jobStartTime && (
                    <InfoField
                        content={[
                            { label: 'Job ran on', value: formatTimestamp(props.jobDelivery.jobStartTime) },
                            { label: 'in', value: props.jobDelivery.jobRunLocation },
                        ]}
                    />
                )}
            </Box>

            <Box className={classes.infoRow}>
                {props.jobDelivery.jobAcqTime && (
                    <InfoField
                        content={{
                            label: 'Acquired on:',
                            value: formatTimestamp(props.jobDelivery.jobAcqTime),
                        }}
                    />
                )}

                {props.jobDelivery.bbdsPublish && (
                    <InfoField
                        content={{ label: 'Delivered at:', value: formatTimestamp(props.jobDelivery.bbdsPublish) }}
                    />
                )}

                <InfoField
                    content={{
                        label: 'BBDS Transaction Id:',
                        value: props.jobDelivery.bbdsTranId,
                        searchKey: 'bbdsTranId',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: 'Run Id:',
                        value: props.jobDelivery.runId,
                        searchKey: 'runId',
                    }}
                    highlightData={props.highlightData}
                />

                <InfoField
                    content={{
                        label: 'Root Step Id:',
                        value: props.jobDelivery.provRootStepid,
                        searchKey: 'provRootStepid',
                    }}
                    highlightData={props.highlightData}
                />
            </Box>

            <Box className={classes.infoRow}>
                <InfoField
                    content={{
                        label: `Delivery URL${pluralSuffix(props.jobDelivery.deliveries?.length)}:`,
                        value: (
                            <>
                                {props.jobDelivery.deliveries?.map((url: string, idx: number) => (
                                    <div key={idx}>
                                        <a
                                            className={classes.link}
                                            onClick={(): void => {
                                                launchUrl(url);
                                            }}
                                        >
                                            {url}
                                        </a>
                                    </div>
                                ))}
                            </>
                        ),
                    }}
                />
            </Box>

            <ExtraHighlightedElements explicitKeys={explicitKeys} highlightData={props.highlightData} />
        </Paper>
    );
};

export default JobDeliverySearchDocCard;
