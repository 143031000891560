import React from 'react';
import { Link, Prompt, RouteComponentProps } from 'react-router-dom';
import { Alert, Box, FormControlLabel, LinearProgress, Switch, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    AlertOptionType,
    AlysMetric,
    AlysOperator,
    JobConfigSearchDoc,
    JobConfigSearchDocRaw,
} from '@webacq/wa-shared-definitions';
import {
    AlertConfiguration,
    AlertConfigurationData,
    AlertConfigurationStateData,
    Toast,
    ToastPosition,
} from '@webacq/wam-ui-components';
import Content from '../components/Content';
import { useAuth } from '../context/auth';
import { getJobConfigRoute, wrapApiCall, wrapApiCall2 } from '../utils';
import { DocTypes, QueryStrings } from '../shared/constants';
import searchService from '../services/searchService';
import wamService from '../services/wamService';
import alysService from '../services/alysService';
import CopyToClipboard from '../components/CopyToClipboard';

const useStyles = makeStyles((theme: Theme) => ({
    alertConfigBox: {
        padding: theme.spacing(2),
    },
    horizSpacer: {
        marginLeft: theme.spacing(2),
    },
}));

interface URLParams {
    jobConfigId: string;
}

const AlertConfig = (props: RouteComponentProps<URLParams>): JSX.Element => {
    const authContext = useAuth();
    const classes = useStyles();

    const jobConfigId = props.match.params.jobConfigId;

    const queryParms = new URLSearchParams(props.history.location.search.toLowerCase());
    const alertConfigId = queryParms.get(QueryStrings.ALERT_CONFIG_ID);

    const [alertMetrics, setAlertMetrics] = React.useState<AlysMetric[]>([]);
    const [alertConditionOperators, setAlertConditionOperators] = React.useState<AlysOperator[]>([]);
    const [alertTemplates, setAlertTemplates] = React.useState<AlertOptionType[]>();
    const [alertCfgs, setAlertCfgs] = React.useState<AlertConfigurationStateData[]>([]);
    const [jobConfig, setJobConfig] = React.useState<JobConfigSearchDoc | null>(); // use undefined for initial state, later set to null to indicate error
    const [dataSourceEnabled, setDataSourceEnabled] = React.useState(false);
    const [isBusy, setIsBusy] = React.useState(false);
    const [toastData, setToastData] = React.useState<{
        message: string | JSX.Element;
        severity: 'error' | 'success' | 'info';
        duration?: number;
    }>();

    React.useEffect(() => {
        (async () => {
            await Promise.all([await loadAlertTemplates(), await loadAlertConfigs()]);
        })();
    }, []);

    const loadAlertTemplates = async () => {
        return wrapApiCall(
            authContext,
            async () => {
                const templates = await wamService.getAlertTemplates();

                setAlertTemplates(
                    templates.map((template) => ({
                        id: template.id,
                        name: template.title || '',
                        description: template.title || '',
                    }))
                );
            },
            (e: Error) => {
                console.error('error loading alert templates', e);
                setToastData({
                    message: (
                        <>
                            The alert template did not load correctly, please try again.
                            <span className={classes.horizSpacer} />
                            <CopyToClipboard tooltip="Copy full error message" text2Copy={e.message} size="inherit" />
                        </>
                    ),
                    severity: 'error',
                });
            }
        );
    };

    const loadAlertConfigs = async () => {
        if (jobConfigId) {
            setToastData(undefined);
            setIsBusy(true);

            wrapApiCall(
                authContext,
                async () => {
                    const [metrics, operators] = await Promise.all([
                        alysService.getMetrics(),
                        alysService.getOperators(),
                    ]);

                    setAlertMetrics(metrics);

                    const operatorNameMap = {
                        'less than': '<',
                        'greater than': '>',
                    };

                    setAlertConditionOperators(
                        operators
                            .filter((operator) => operator.name !== 'not between')
                            .map((operator) => ({
                                ...operator,
                                name: operatorNameMap[operator.name] || operator.name,
                            }))
                    );

                    const [jobConfigs, dataSource, alertConfigData] = await Promise.all([
                        searchService.search<JobConfigSearchDocRaw, JobConfigSearchDoc>(
                            `${jobConfigId}`,
                            DocTypes.JOB_CONFIG,
                            1
                        ),
                        alysService.getDataSource(jobConfigId),
                        alysService.loadAlertConfigs(jobConfigId),
                    ]);

                    setJobConfig(jobConfigs.data?.docs[0] || null);

                    if (dataSource) {
                        setDataSourceEnabled(!dataSource.disabled);
                    } else {
                        setDataSourceEnabled(false);
                    }

                    const alertConfigs = alertConfigData.map((data) =>
                        AlertConfigurationData.fromAlysAlertConfigData(data)
                    );

                    await resolveUuidsInAlertConfigs(alertConfigs);

                    setAlertCfgs(
                        alertConfigs
                            .map((alertConfig) => {
                                const data = new AlertConfigurationStateData();

                                data.config = alertConfig;
                                data.modified = false;
                                data.collapsed = alertConfigId ? alertConfigId !== alertConfig.alertConfigId : false;
                                data.useTransition = true;

                                return data;
                            })
                            .sort((lhs, rhs) => lhs.config.alertConfigId.localeCompare(rhs.config.alertConfigId))
                    );

                    if (!dataSource) {
                        setToastData({
                            message: 'Alerts have not been configured yet for this job configuration',
                            severity: 'info',
                            duration: 10000,
                        });
                    } else {
                        setToastData({
                            message: 'Alert Configuration loaded',
                            severity: 'success',
                            duration: 4000,
                        });
                    }
                },
                (e: Error) => {
                    console.error('error loading alert configs', e);
                    setJobConfig(null);
                    setAlertCfgs([]);
                    setToastData({ message: e.message, severity: 'error' });
                },
                () => setIsBusy(false)
            );
        }
    };

    const loadAlertConfig = async (alertConfigId: string) => {
        return wrapApiCall(
            authContext,
            async () => {
                const alertConfigData = await alysService.loadAlertConfig(alertConfigId);
                const alertConfig = AlertConfigurationData.fromAlysAlertConfigData(alertConfigData);

                await resolveUuidsInAlertConfigs([alertConfig]);

                return alertConfig;
            },
            (e: Error) => {
                console.error('error loading alert config', e);
                setToastData({
                    message: (
                        <>
                            The template did not load correctly, please try again.
                            <span className={classes.horizSpacer} />
                            <CopyToClipboard tooltip="Copy full error message" text2Copy={e.message} size="inherit" />
                        </>
                    ),
                    severity: 'error',
                });
            }
        );
    };

    const saveAlertConfigs = async (jobConfigId: string): Promise<boolean> => {
        if (jobConfigId && jobConfig) {
            setToastData(undefined);
            setIsBusy(true);

            const alertConfigData = alertCfgs.map((alertCfg) => alertCfg.config.toAlysAlertConfigData());

            return wrapApiCall2(
                authContext,
                false,
                async () => {
                    await alysService.saveAlertConfigs(
                        jobConfigId,
                        alertConfigData,
                        authContext.authData.userId || '',
                        !dataSourceEnabled
                    );

                    setToastData({ message: 'Alert Configuration saved', severity: 'success', duration: 4000 });
                    return true;
                },
                (e: Error) => {
                    console.error('error saving alert configs', e);
                    setToastData({ message: e.message, severity: 'error' });
                },
                () => setIsBusy(false)
            );
        }

        return false;
    };

    const resolveUuidsInAlertConfigs = async (alertCfgs: AlertConfigurationData[]): Promise<void> => {
        const uuids = alertCfgs
            .map((cfg) => cfg.notifications.sendMsgSettings.data.recipients)
            .flat()
            .map((recipient) => recipient.uuid)
            .filter((uuid) => uuid) as number[]; // filter out invalid records

        const result = await wamService.resolveBBUserIds(uuids);

        if (result.message) {
            setToastData({ message: result.message, severity: 'error' });
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const lookupCache: Record<number, any> = {};
        result.users.forEach((user) => {
            lookupCache[user.uuid] = user;
        });

        alertCfgs.forEach((alertCfg) => {
            alertCfg.notifications.sendMsgSettings.data.recipients =
                alertCfg.notifications.sendMsgSettings.data.recipients.map(
                    (recipient) => lookupCache[recipient.uuid] || recipient
                );
        });
    };

    const disableDataSource = async (jobConfigId: string, disabled: boolean): Promise<void> => {
        if (jobConfigId && jobConfig) {
            setToastData(undefined);
            setIsBusy(true);

            wrapApiCall(
                authContext,
                async () => {
                    setDataSourceEnabled(!disabled);

                    await alysService.disableDataSource(jobConfigId, disabled, authContext.authData.userId || '');

                    // reload to get accurate status
                    const dataSource = await alysService.getDataSource(jobConfigId);

                    if (dataSource) {
                        setDataSourceEnabled(!dataSource.disabled);
                    } else {
                        setDataSourceEnabled(false);
                    }
                },
                (e: Error) => setToastData({ message: e.message, severity: 'error' }),
                () => setIsBusy(false)
            );
        }
    };

    const getContent = (): JSX.Element => {
        if (jobConfig === null) {
            return <Alert severity="error">{`A job configuration with id '${jobConfigId}' does not exist`}</Alert>;
        }

        return (
            <>
                <Prompt
                    when={alertCfgs.some((alertCfg) => alertCfg.modified)}
                    message="This Alert Configuration has unsaved changes. Are you sure you want to leave?"
                />

                {toastData && (
                    <Toast
                        open={!!toastData.message}
                        message={toastData.message}
                        severity={toastData.severity}
                        duration={toastData.duration}
                        position={ToastPosition.TOP_CENTER}
                        onClose={() => setToastData(undefined)}
                    />
                )}

                {isBusy && <LinearProgress />}

                <Box display="flex" width="100%" height="100%" className={classes.alertConfigBox}>
                    <AlertConfiguration
                        maxConfigurations={3}
                        alertOptions={alertTemplates || []}
                        alertMetrics={alertMetrics}
                        alertConditionOperators={alertConditionOperators}
                        alertConfigs={alertCfgs}
                        onQueryUsers={(query, maxRecords) => wamService.queryBBUserIds(query, maxRecords)}
                        onLoadAlertConfig={(alertConfigId) => loadAlertConfig(alertConfigId)}
                        onUpdated={(alertCfgs) => setAlertCfgs(alertCfgs)}
                        onSaveRequested={() => saveAlertConfigs(jobConfigId || '')}
                    />
                </Box>
            </>
        );
    };

    const subtitle = (
        <>
            Alert Configuration:
            <span className={classes.horizSpacer} />
            <Tooltip title="View job config details">
                <Link to={getJobConfigRoute(jobConfigId)}>{jobConfig?.jobConfigName || jobConfigId}</Link>
            </Tooltip>
            <span className={classes.horizSpacer} />
            <span className={classes.horizSpacer} />
            {jobConfig !== null && (
                <FormControlLabel
                    className={classes.horizSpacer}
                    control={
                        <Switch
                            size="small"
                            checked={dataSourceEnabled}
                            onChange={async (event) => {
                                if (jobConfigId) {
                                    const disabled = !event.target.checked;
                                    await disableDataSource(jobConfigId, disabled);
                                }
                            }}
                        />
                    }
                    label={dataSourceEnabled ? 'enabled' : 'disabled'}
                />
            )}
        </>
    );

    return (
        <Content subtitle={subtitle} quickLinkTail={`/AC ${encodeURIComponent(jobConfigId)}`}>
            {getContent()}
        </Content>
    );
};

export default AlertConfig;
